import { useState } from "react";
import {useLocation, useNavigate} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import Swal from "sweetalert2";
import axios from "axios";

const Home = () => {

    const navigate = useNavigate();
    const [bids, setbids] = useState([]);

    const [openSession, setopenSession] = useState("activeButton");
    const [closeSession, setcloseSession] = useState("inactiveButton");
    const [display, setdisplay] = useState("");
    const [game_name, setggame_name] = useState("jodi");

    const [session, setsession] = useState("");

    let data = useLocation();

    let marketName = data.state[0].market;
    let is_open_status = data.state[0].is_open;



    let removePeople = (e)=>{
        setbids(bids.filter(function(person) {
            return person !== e
        }));
    }

    let addBid = (e)=>{
        if (parseInt(amount) >= 10) {
            if (e === "even") {
                setbids(current => [...current, {amount: amount, number: "00", session: session}]);
                setbids(current => [...current, {amount: amount, number: "11", session: session}]);
                setbids(current => [...current, {amount: amount, number: "22", session: session}]);
                setbids(current => [...current, {amount: amount, number: "33", session: session}]);
                setbids(current => [...current, {amount: amount, number: "44", session: session}]);
                setbids(current => [...current, {amount: amount, number: "55", session: session}]);
                setbids(current => [...current, {amount: amount, number: "66", session: session}]);
                setbids(current => [...current, {amount: amount, number: "88", session: session}]);
                setbids(current => [...current, {amount: amount, number: "77", session: session}]);
                setbids(current => [...current, {amount: amount, number: "99", session: session}]);
            } else if (e === "odd") {
                setbids(current => [...current, {amount: amount, number: "05", session: session}]);
                setbids(current => [...current, {amount: amount, number: "16", session: session}]);
                setbids(current => [...current, {amount: amount, number: "27", session: session}]);
                setbids(current => [...current, {amount: amount, number: "38", session: session}]);
                setbids(current => [...current, {amount: amount, number: "49", session: session}]);
                setbids(current => [...current, {amount: amount, number: "50", session: session}]);
                setbids(current => [...current, {amount: amount, number: "61", session: session}]);
                setbids(current => [...current, {amount: amount, number: "72", session: session}]);
                setbids(current => [...current, {amount: amount, number: "83", session: session}]);
                setbids(current => [...current, {amount: amount, number: "94", session: session}]);
            }
        } else {
            Swal.fire({
                toast: true,
                title: 'Oops...',
                text: "Bid Amount must be above 10 INR"
            })
        }

    }

    const [amount, setamount] = useState("");
    const [bid, setbid] = useState("");

    let loginSubmit = () =>{

        let numberString,amountString,typesString

        let numberArray = []
        let amountArray = []
        let typesArray = []

        bids.map((result) => {
            numberArray.push(result.number)
            amountArray.push(result.amount)
            typesArray.push(result.session)
        })

        numberString = numberArray.join(",")
        amountString = amountArray.join(",")
        typesString = typesArray.join(",")

        var form = new FormData();

        form.append("session", localStorage.getItem("token"));
        form.append("mobile", localStorage.getItem("mobile"));

        form.append("bazar", marketName);
        form.append("number", numberString);
        form.append("amount", amountString);
        form.append("types", typesString);
        form.append("game", game_name);

        axios
            .post("https://sattads.com/manage/"+`/api2/bet.php`,form)
            .then((response) => {
                if (response.data.nosession){
                    Swal.fire({
                        toast: true,
                        title: 'Oops...',
                        text: "Session Expired"
                    }).then((e)=>{
                        localStorage.clear()
                        window.location.href = "/login";
                    })
                }
                if(response.data.success === "0"){
                    Swal.fire({
                        toast: true,
                        title: 'Oops...',
                        text: response.data.msg
                    })
                } else {
                    navigate("/home")
                }
            })
    }


    return (<>
            <div className="RootView">
                <div className="RootWidth">

                    <div className="loginDiv">

                        <div className="toolbar">
                            <div>
                                <img onClick={()=>{window.history.go(-1)}}  src="../images/back.png" onClick={()=>{window.history.go(-1)}} style={{padding: "5px"}}/>
                                <span>{marketName}, {game_name}</span>
                            </div>
                        </div>


                        <div className="wallet-box upi_apps">
                            <span className="heading"  style={{color:'white',borderBottom:"solid 1px #fff"}}>₹ {localStorage.getItem("wallet")}</span>
                            <span className="subheading"  style={{color:'white'}}>Balance</span>
                        </div>


                        <div style={{padding:"5px"}}>
                            <div className="date-block">
                                <span style={{color:"#00839F"}}>
                                    {new Date().getDate() + '/'+new Date().getMonth()+1+ '/'+new Date().getFullYear()}
                                </span>

                            </div>
                        </div>

                        <div className="main-game-screen">

                            <input
                                className="edittext"
                                name="mobile"
                                type="number"
                                value={amount}
                                onChange={(e)=>{setamount(e.target.value)}}
                                placeholder="Enter Points"
                            />

                            <div className="spdptp">
                                <button className="button" onClick={()=>{addBid('odd')}}>
                                    <b>Half Bracket</b>
                                </button>
                                <button className="button" onClick={()=>{addBid('even')}}>
                                    <b>Full Bracket</b>
                                </button>
                            </div>


                            { bids.map((result) => { return (
                                <div className="bet-list" key={result.number}>
                                    <span>
                                        {result.number}
                                    </span>
                                    <span>
                                       {result.amount}
                                    </span>
                                    <span>
                                        {result.session} <img onClick={()=>{removePeople(result)}} src="../images/delete.png" />
                                    </span>
                                </div>
                            )})}

                            <button className="button" onClick={loginSubmit}>
                                <b>SUBMIT</b>
                            </button>

                        </div>

                    </div>


                </div>
            </div>


        </>
    );
};

export default Home;
