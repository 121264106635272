import { useCallback, useState, useEffect } from "react";
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import styles from "../style/Login.module.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";
import { bindActionCreators } from "redux";
import { actions } from "../state/index";

const Login = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const state = useSelector(state => state.session);
    const dispatch = useDispatch();
    const { setSession, getSession } = bindActionCreators(actions, dispatch);

    const [mobileNumber, setMobileNumber] = useState("");
    const [pass, setPassword] = useState("");
    const [name, setName] = useState("");
    const [cpass, setcpass] = useState("");
    const [showLoading, setshowLoading] = useState("none");
    const [ccpass, setccpass] = useState("");
    const [code, setcode] = useState("");
    const [id, setid] = useState("");

    const baseurl = "https://sattads.com/manage/";

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const referCode = params.get('REFER');
        if (referCode) {
            setcode(referCode);
        }
    }, [location.search]);

    const loginSubmit = (event) => {
        event.preventDefault();

        if (cpass === "") {
            setshowLoading("none");
            Swal.fire({
                toast: true,
                title: 'Oops...',
                text: "Enter valid password"
            });
        } else if (cpass !== ccpass) {
            setshowLoading("none");
            Swal.fire({
                toast: true,
                title: 'Oops...',
                text: "Password does not match"
            });
        } else {
            var form = new FormData();
            form.append("mobile", mobileNumber);
            form.append("pass", cpass);
            form.append("refcode", code);
            form.append("name", name);
            form.append("id", id);

            axios
                .post(`${baseurl}/api2/register.php`, form)
                .then((response) => {
                    console.log(response);
                    if (response.data.success === "0") {
                        setshowLoading("none");
                        Swal.fire({
                            toast: true,
                            title: 'Oops...',
                            text: response.data.msg
                        });
                    } else {
                        setshowLoading("none");
                        setSession(response.data.token, mobileNumber);
                        navigate("/home");
                    }
                });

            if (state) {
                return <Navigate to={"/home"} />;
            }
        }

        if (state) {
            return <Navigate to={"/home"} />;
        }
    };

    const onForgotClick = useCallback(() => {
        navigate("/forgot");
    }, [navigate]);

    return (
        <div className="RootView">
            <div className="RootWidth authBack">
                <div style={{ display: showLoading }}>
                    <div className="loader-parent">
                        <div className="loader-block">
                            <div className="loader"></div>
                        </div>
                    </div>
                </div>

                <div className="loginBackDesign">
                    <h1>Welcome to {process.env.REACT_APP_NAME}</h1>
                    <p>Create new account entering given details below</p>
                </div>

                <div className={styles.loginDiv}>
                    <img className="logoIcon" alt="" src="../images/logo.png" />

                    <form
                        className={styles.frameForm}
                        method="post"
                        onSubmit={loginSubmit}
                    >
                        <div className="pageHeadings" style={{ width: "100%", marginBottom: "10px" }}>Create new account</div>
                        <input
                            className="edittext"
                            name="name"
                            type="text"
                            style={{ width: "100%", marginBottom: "10px" }}
                            value={name}
                            onChange={(e) => { setName(e.target.value) }}
                            placeholder="Enter Name"
                        />

                        <input
                            className="edittext"
                            name="mobile"
                            style={{ width: "100%", marginBottom: "10px" }}
                            type="number"
                            minLength="10"
                            maxLength="10"
                            value={mobileNumber}
                            onChange={(e) => { setMobileNumber(e.target.value) }}
                            placeholder="Enter mobile number"
                        />

                        <input
                            className="edittext"
                            type="password"
                            style={{ width: "100%", marginBottom: "10px" }}
                            name="cpass"
                            value={cpass}
                            placeholder="Create Password"
                            onChange={(e) => { setcpass(e.target.value) }}
                        />

                        <input
                            className="edittext"
                            type="password"
                            style={{ width: "100%", marginBottom: "10px" }}
                            name="ccpass"
                            value={ccpass}
                            placeholder="Confirm Password"
                            onChange={(e) => { setccpass(e.target.value) }}
                        />

                        <input
                            className="edittext"
                            type="text"
                            style={{ width: "100%", marginBottom: "10px" }}
                            name="code"
                            value={code}
                            placeholder="Promo Code (Optional)"
                            onChange={(e) => { setcode(e.target.value) }}
                        />

                        <button className="button">
                            <b>REGISTER</b>
                        </button>

                        <button className="button">
                            <Link to="/login">
                                <b>ALREADY HAVE ACCOUNT</b>
                            </Link>
                        </button>

                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
