import { useState } from "react";
import {useLocation, useNavigate} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import Swal from "sweetalert2";
import axios from "axios";
import { Button } from "bootstrap";
import {Link} from "react-router-dom";

const Home = () => {

    const navigate = useNavigate();
    const [bids, setbids] = useState([]);
    const [suggestions, setsuggestions] = useState([]);

    const [crossingNumber, setcrossingNumber] = useState("");
    const [crossingAmount, setcrossingAmount] = useState("");
    const [crossingTotal, setcrossingTotal] = useState("Place bid for ₹0");
    
    const [crossingBets, setcrossingBets] = useState([]);

    

    const [openNumber, setopenNumber] = useState("");
    const [openAmount, setopenAmount] = useState("");
    const [openTotal, setopenTotal] = useState("Place bid for ₹0");
    
    const [openBets, setopenBets] = useState([]);
    const [openPalti, setopenPalti] = useState("false");

    
    const [showLoading, setshowLoading] = useState("none");

    const [openHNumber, setopenHNumber] = useState("");
    const [openHAmount, setopenHAmount] = useState("");
    const [openHTotal, setopenHTotal] = useState("");
    const [openHErrorMsg, setopenHErrorMsg] = useState("");
    
    const [openHBets, setopenHBets] = useState([]);
    const [openHA, setopenHA] = useState("false");
    const [openHB, setopenHB] = useState("false");


    
    const [noNumber, setnoNumber] = useState("");
    const [noNumber2, setnoNumber2] = useState("");
    const [noNumberAmount, setnoNumberAmount] = useState("");
    const [noNumberTotal, setnoNumberTotal] = useState("Place bid for ₹0");
    
    const [noNumberBets, setnoNumberBets] = useState([]);

    const [jodiCut, setjodiCut] = useState("false");

    const [openSession, setopenSession] = useState("activeButton");
    const [closeSession, setcloseSession] = useState("inactiveButton");
    const [display, setdisplay] = useState("");

    const [session, setsession] = useState("OPEN");
    const [totalText, settotalText] = useState("Place bid for ₹0");

    const [jantriVals, setjantriVals] = useState([]);

    let timing = ""

    let data = useLocation();


    let marketName = data.state[0].market;
    let is_open_status = data.state[0].is_open;
    let game_name = data.state[0].game;
    if (data.state[0].timing !== undefined) {
        timing = data.state[0].timing
    }

    if (is_open_status === "0"){
        setopenSession("inactiveButton")
        setcloseSession("activeButton")
        setsession("CLOSE")
    }




    let addBid = (bid, amount)=>{
        const index = bids.findIndex(x => x.number == bid)
        var modifiedArray = bids
        if (index !== -1) {
            //Remove the element from the array
            modifiedArray.splice(index, 1);
        }
        let bidObj = {amount: amount, number:bid}
        setbids([...modifiedArray, bidObj])
        let sum = modifiedArray.reduce(function(prev, current) {
            return prev + +current.amount
          }, 0);

          console.log(modifiedArray)
          
          settotalText("Place bid for ₹"+(sum+parseInt(amount)));
    }



    const remDup= e => [...new Set(e)].sort().join("");
    let changeNumber = (bid)=>{
        bid = remDup(bid)
        setcrossingNumber(bid)
        var numbers = bid.split("");
        var results = [];

        console.log(jodiCut);

        if(jodiCut == "true"){
            for (var i = 0; i < numbers.length; i++)
                for (var j = 0; j < numbers.length; j++)
                    if (numbers[i] != numbers[j])
                        results.push([numbers[i]+""+numbers[j]]);
        } else {
            for (var i = 0; i < numbers.length; i++)
                for (var j = 0; j < numbers.length; j++)
                    results.push([numbers[i]+""+numbers[j]]);
        }
        setcrossingTotal("Place bid for ₹"+results.length*crossingAmount);

        setcrossingBets(results);
        console.log(results);
//        setbids(current => [...current, bidObj]);
    }

    let changeNumber2 = (state, bid)=>{
        setcrossingNumber(bid)
        var numbers = bid.split("");
        var results = [];

        console.log(jodiCut);

        if(state){
            for (var i = 0; i < numbers.length; i++)
                for (var j = 0; j < numbers.length; j++)
                    if (numbers[i] != numbers[j])
                        results.push([numbers[i]+""+numbers[j]]);
        } else {
            for (var i = 0; i < numbers.length; i++)
                for (var j = 0; j < numbers.length; j++)
                    results.push([numbers[i]+""+numbers[j]]);
        }
        
        setcrossingTotal("Place bid for ₹"+results.length*crossingAmount);

        setcrossingBets(results);
        console.log(results);
//        setbids(current => [...current, bidObj]);
    }

  let changeNoNumber = (state, val)=>{

        let firstN, secN, amount;

        if(state == 1){
            if(val.length > 2){
                val = val.substring(0,2)
            }
            setnoNumber(val);
            firstN = val;
            secN = noNumber2;
            amount = noNumberAmount;
        } else if(state == 2){
            if(val.length > 2){
                val = val.substring(0,2)
            }
            setnoNumber2(val);
            firstN = noNumber;
            secN = val;
            amount = noNumberAmount;
        } else if(state == 3){
            setnoNumberAmount(val);
            firstN = noNumber;
            secN = noNumber2;
            amount = val;
        }

        var results = [];

        if(firstN != "" && secN != ""){
            for(var a = firstN; a <= secN; a++){
                let num = ""
                if(a < 10){
                    if(("0"+a).length == 2){
                        num = "0"+a; 
                    } else {
                        num = a; 
                    }
                } else {
                    num = a;
                }
                if(num != ""){
                    results.push(num);
                }
            }
        }

        setnoNumberTotal("Place bid for ₹"+results.length*amount);

        setnoNumberBets(results);
        console.log(results);
//        setbids(current => [...current, bidObj]);
    }

    let changeOpenNumber = (state, val)=>{

        let firstN, palti, amount;

        if(state == 1){
            setopenNumber(val);
            firstN = val;
            palti = openPalti;
            amount = openAmount;
        } else if(state == 2){
            setopenPalti(val+"");
            firstN = openNumber;
            palti = val+"";
            amount = openAmount;
        } else if(state == 3){
            setopenAmount(val);
            firstN = openNumber;
            palti = openPalti;
            amount = val;
        }

        var results = [];

        if(firstN != "" && palti != ""){
            for(var a = 0; a <= firstN.length; a = a+2){
                if(firstN.length > a+1){
                    let num = ""
                    if(palti == "true"){
                        num = firstN.charAt(a)+firstN.charAt(a+1); 
                        if(!results.includes(num)){
                        results.push(num);
                        }
                        
                        let num2 = firstN.charAt(a+1)+firstN.charAt(a); 
                        if(num2 != num && !results.includes(num2)){
                            results.push(num2);
                        }
                        
                    } else {
                        num = firstN.charAt(a)+firstN.charAt(a+1); 
                        if(!results.includes(num)){
                            results.push(num);
                        }
                    }
                }
            }
        }

    
        var totalAmount = openHTotal+results.length*amount;
        setopenTotal("Place bid for ₹"+totalAmount);

        setopenBets(results);
        console.log(results);
//        setbids(current => [...current, bidObj]);
    }

    
    let changeOpenHNumber = (state, val)=>{

        let firstN, a, b , amount;

        if(state == 1){
            
            val = remDup(val)
            setopenHNumber(val);
            firstN = val;
            a = openHA;
            b = openHB;
            amount = openHAmount;
        } else if(state == 2){
            setopenHA(val+"");
            firstN = openHNumber;
            a = val+"";
            b = openHB;
            amount = openHAmount;
        } else if(state == 3){
            setopenHB(val+"");
            firstN = openHNumber;
            a = openHA;
            b = val+"";;
            amount = openHAmount;
        } else if(state == 4){
            setopenHAmount(val);
            firstN = openHNumber;
            a = openHA;
            b = openHB;
            amount = val;
        }

        if(a == "false" && b == "false"){
            setopenHErrorMsg("Please Select Harup Side.");
        } else {
            let totalAms = 0;
            var results = [];
            setopenHErrorMsg("");
            if(firstN != "" && a != "" && b != ""){
                for(var ac = 0; ac <= firstN.length; ac++){
                    if(firstN.length > ac){
                        var tempA = "";
                        let num = firstN.charAt(ac);
                        if(a == "true"){
                            tempA += "A"
                        } 
                        if(b == "true"){
                            tempA += "B"
                        }
                        if(tempA != ""){
                            num = num+" "+tempA;
                            totalAms += amount*tempA.length;
                        } else {
                            totalAms += amount;
                        }
                        results.push(num);
                    }
                }
            }
    
            setopenHTotal(totalAms);
            var totalAmount = openBets.length*openAmount+totalAms;
            setopenTotal("Place bid for ₹"+totalAmount);
    
            setopenHBets(results);
        }

    }


    let changeNumber3 = (amount)=>{
        setcrossingAmount(amount)
        setcrossingTotal("Place bid for ₹"+crossingBets.length*amount);
        }
    

    let handleChangeCut = (target)=>{
        console.log("cc"+target);
        setjodiCut(target+"");
    
        changeNumber2(target,crossingNumber)
        
        console.log(jodiCut);
    }

 
    let loginSubmit = () =>{
        setshowLoading("block");

        let error = false

        let numberString,amountString

        let numberArray = []
        let amountArray = []

        bids.map((result) => {
            if(parseInt(result.amount) < parseInt(localStorage.getItem("min_bet")) || parseInt(result.amount) > parseInt(localStorage.getItem("max_bet"))){
                error = true
            }
            numberArray.push(result.number)
            amountArray.push(result.amount)
        })

        if(error){
            setshowLoading("none");
            Swal.fire({
                toast: true,
                title: 'Oops...',
                text: "You can only place bet between "+localStorage.getItem("min_bet")+" to "+localStorage.getItem("max_bet")
            })
        } else {

                
            numberString = numberArray.join(",")
            amountString = amountArray.join(",")

            console.log(numberString)
            console.log(amountString)

            var form = new FormData();

            form.append("session", localStorage.getItem("token"));
            form.append("mobile", localStorage.getItem("mobile"));

            form.append("bazar", marketName);
            form.append("number", numberString);
            form.append("amount", amountString);
            form.append("game", game_name);
        
            axios
                .post("https://sattads.com/manage/"+`/api2/bet.php`,form)
                .then((response) => {
                    setshowLoading("none");
                    if (response.data.nosession){
                        Swal.fire({
                            toast: true,
                            title: 'Oops...',
                            text: "Session Expired"
                        }).then((e)=>{
                            localStorage.clear()
                            window.location.href = "/login";
                        })
                    }
                    if(response.data.success === "0"){
                        Swal.fire({
                            toast: true,
                            title: 'Oops...',
                            text: response.data.msg
                        }).then((result) => {
                            window.location.href = "/home";
                        });
                    }
                    if(response.data.success === "2"){
                        localStorage.setItem("wallet", response.data.wallet)
                        Swal.fire({
                            title: 'Oops...',
                            text: response.data.msg,
                            icon: "warning"
                        }).then((result) => {
                            window.location.reload(); 
                        });
                    }
                     else {
                        localStorage.setItem("wallet", response.data.wallet)
                        Swal.fire({
                            title: "Success!",
                            text: "Your game placed succesfully",
                            icon: "success"
                        }).then((result) => {
                            window.location.reload(); 
                        });

                        
                    }
                })

        }
        

    }

    
    let groupSubmit = (state) =>{
        setshowLoading("block");
        let error = false

        let numberString,amountString,amountString2 = ""

        let numberArray = []

        if(state == 0){
            crossingBets.map((result) => {
                    numberArray.push(result)
                
            })
            amountString = crossingAmount
        } else if(state == 1){
            
            if(noNumberAmount == ""){
                error = true
            } else {
                noNumberBets.map((result) => {
                    numberArray.push(result)
                
            })
            amountString = noNumberAmount
            }
           
        } else if(state == 3){ 
            openBets.map((result) => {
                numberArray.push(result)
            })
            openHBets.map((result) => {
                numberArray.push(result)
            })
            amountString = openAmount
            amountString2 = openHAmount

            if(amountString == "" && amountString2 == ""){
                error = true
            }
    
            if(openBets.length > 0 && amountString == ""){
                error = true;
            }
            
            if(openHBets.length > 0 && amountString2 == ""){
                error = true;
            }
        
            
        }

        
        if(amountString != ""){
            if(parseInt(amountString) < parseInt(localStorage.getItem("min_bet")) || parseInt(amountString) > parseInt(localStorage.getItem("max_bet"))){
                error = true
            }
        }
         
        if(amountString2 != ""){
            if(parseInt(amountString2) < parseInt(localStorage.getItem("min_bet")) || parseInt(amountString2) > parseInt(localStorage.getItem("max_bet"))){
                error = true
            }
        }

        if(error){
            setshowLoading("none");
            Swal.fire({
                toast: true,
                title: 'Oops...',
                text: "You can only place bet between "+localStorage.getItem("min_bet")+" to "+localStorage.getItem("max_bet")
            })
        } else {

            numberString = numberArray.join(",")

            var form = new FormData();

            form.append("session", localStorage.getItem("token"));
            form.append("mobile", localStorage.getItem("mobile"));

            if(amountString2 != ""){        
                console.log("am2"+amountString2)
                form.append("amount2", amountString2);
            }
            if(amountString != ""){        
                form.append("amount", amountString);
            }
            form.append("bazar", marketName);
            form.append("number", numberString);
            form.append("game", game_name);
        
            axios
                .post("https://sattads.com/manage/"+`/api2/bet.php`,form)
                .then((response) => {
                    setshowLoading("none");
                    if (response.data.nosession){
                        Swal.fire({
                            toast: true,
                            title: 'Oops...',
                            text: "Session Expired"
                        }).then((e)=>{
                            localStorage.clear()
                            window.location.href = "/login";
                        })
                    }
                    if(response.data.success === "0"){
                        Swal.fire({
                            toast: true,
                            title: 'Oops...',
                            text: response.data.msg
                        }).then((result) => {
                            window.location.href = "/home";
                        });
                    } else {
                        localStorage.setItem("wallet", response.data.wallet)
                        Swal.fire({
                            title: "Success!",
                            text: "Your game placed succesfully",
                            icon: "success"
                        }).then((result) => {
                            window.location.reload(); 
                        });
                    }
                })

        }
    }

    let jodiNumbers = ["01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31","32","33","34","35","36","37","38","39","40","41","42","43","44","45","46","47","48","49","50","51","52","53","54","55","56","57","58","59","60","61","62","63","64","65","66","67","68","69","70","71","72","73","74","75","76","77","78","79","80","81","82","83","84","85","86","87","88","89","90","91","92","93","94","95","96","97","98","99","00"];
      
    let singles = ["1","2","3","4","5","6","7","8","9","0"];
      
    return (<>
            <div className="RootView">
                <div className="RootWidth">

                <div style={{display:showLoading}}>
                    <div class="loader-parent">
                    <div class="loader-block">
                    <div class="loader"></div> 
                    </div>
                    </div>
                </div>

                    <div className="loginDiv">

                        <div className="toolbar">
                            <div>
                                <img onClick={()=>{window.history.go(-1)}}  src="../images/back.png" style={{padding: "5px"}}/>
                                <span>{marketName}</span>
                            </div>
                            <Link to={"/wallet"}>
                                <div>
                                    <img className="wallet-img" src="../images/wallet_figma.png"/>
                                    <span className="wallet-text">{localStorage.getItem('wallet')}</span>
                                </div>
                            </Link>
                        </div>

                        <div className="row" style={{margin:"0"}}>
                            <>
                                <ul className="nav nav-tabs" id="myTab" role="tablist" style={{marginBottom:"15px"}}>
                                    <li className="nav-item" role="presentation" style={{padding:"0"}}>
                                    <button
                                        className="nav-link active"
                                        id="home-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#home"
                                        type="button"
                                        role="tab"
                                        aria-controls="home"
                                        style={{color:"black"}}
                                        aria-selected="true"
                                    >
                                        JANTRI
                                    </button>
                                    </li>
                                    <li className="nav-item" role="presentation" style={{padding:"0"}}>
                                    <button
                                        className="nav-link"
                                        id="profile-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#profile"
                                        style={{color:"black"}}
                                        type="button"
                                        role="tab"
                                        aria-controls="profile"
                                        aria-selected="false"
                                    >
                                        CROSSING
                                    </button>
                                    </li>
                                    <li className="nav-item" role="presentation" style={{padding:"0"}}>
                                    <button
                                        className="nav-link"
                                        id="contact-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#contact"
                                        style={{color:"black"}}
                                        type="button"
                                        role="tab"
                                        aria-controls="contact"
                                        aria-selected="false"
                                    >
                                        NO TO NO
                                    </button>
                                    </li>

                                    <li className="nav-item" role="presentation" style={{padding:"0"}}>
                                    <button
                                        className="nav-link"
                                        id="open-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#openplay"
                                        style={{color:"black"}}
                                        type="button"
                                        role="tab"
                                        aria-controls="openplay"
                                        aria-selected="false"
                                    >
                                        OPEN PLAY
                                    </button>
                                    </li>
                                </ul>
                                <div className="tab-content" id="myTabContent">
                                    <div
                                    className="tab-pane fade show active"
                                    id="home"
                                    role="tabpanel"
                                    aria-labelledby="home-tab"
                                    >

                                        <div className="place-parent">
                                            <button style={{marginTop:"10px"}} className="button" onClick={(e)=>{loginSubmit()}} >
                                                {totalText}
                                            </button>
                                        </div>

                                        <div className="jantri-parent" style={{paddingBottom:"10px", margin:"0"}}>
                                        {jodiNumbers.map((number) => {
                                            return (
                                                <div className="jantri-box2">
                                                    <span>{number}</span>
                                                    <input className="edittext" type="number"
                                                        style={{width:"100%", marginBottom:"10px"}}
                                                        onChange={(e)=>{addBid(number,e.target.value)}}
                                                    />

                                                </div>
                                            )
                                        })}

                                        </div>

                                        <span>Andar Haruf</span>
                                        <div className="jantri-parent" style={{paddingBottom:"10px", margin:"0"}}>
                                        {singles.map((number) => {
                                            return (
                                                <div className="jantri-box2">
                                                    <span>{number}</span>
                                                    <input className="edittext" type="number"
                                                        style={{width:"100%", marginBottom:"10px"}}
                                                        onChange={(e)=>{addBid(number+" A",e.target.value)}}
                                                    />

                                                </div>
                                            )
                                        })}

                                        </div>
                                        <span>Bahar Haruf</span>
                                        <div className="jantri-parent" style={{paddingBottom:"80px", margin:"0"}}>
                                        {singles.map((number) => {
                                            return (
                                                <div className="jantri-box2">
                                                    <span>{number}</span>
                                                    <input className="edittext" type="number"
                                                        style={{width:"100%", marginBottom:"10px"}}
                                                        onChange={(e)=>{addBid(number+" B",e.target.value)}}
                                                    />

                                                </div>
                                            )
                                        })}

                                        </div>

                                        
                                    </div>
                                    
                                    <div
                                        className="tab-pane fade"
                                        id="profile"
                                        role="tabpanel"
                                        aria-labelledby="profile-tab"
                                    >
                                           <div className="place-parent">
                                            <button style={{marginTop:"10px"}} className="button" onClick={(e)=>{groupSubmit(0)}} >
                                                {crossingTotal}
                                            </button>
                                        </div>
                                        
                                      <div className="crossingParent">
                                        <div className="crossing-box">
                                                <span>Enter Numbers</span>
                                                <input className="edittext" type="number"
                                                    style={{width:"100%", marginBottom:"10px"}}
                                                    value={crossingNumber}
                                                    onChange={(e)=>{changeNumber(e.target.value)}}
                                                />
                                            </div>
                                        
                                            <div className="crossing-box">
                                                <span>Enter Amount</span>
                                                <input className="edittext" type="number"
                                                    style={{width:"100%", marginBottom:"10px"}}
                                                    value={crossingAmount}
                                                    onChange={(e)=>{changeNumber3(e.target.value)}}
                                                />
                                            </div>
                                        
                                      </div>
                                      <div style={{display:"flex", justifyContent:"center"}}>
                                        <input type="checkbox" id="jodi_cut" name="jodi_cut" value="jodi_cut"
                                            onChange={e => handleChangeCut(e.target.checked)}
                                        />
                                        <span style={{marginLeft:"10px"}}>Jodi Cut</span>
                                      </div>

                                      <div className="crossing-bets-parent"  style={{paddingBottom:"80px"}}>
                                      {crossingBets.map((number) => {
                                            return (
                                                <div className="crossing-bets-child">
                                                    <input className="edittext" type="text"
                                                    value={number}
                                                            style={{width:"100%", marginBottom:"10px", textAlign:"center"}} readOnly
                                                        />
                                                </div>
                                            )
                                      })}
                                           
                                      </div>

                                    </div>

                                    <div
                                        className="tab-pane fade"
                                        id="contact"
                                        role="tabpanel"
                                        aria-labelledby="contact-tab"
                                    >
                                    
                                        <div className="place-parent">
                                                <button style={{marginTop:"10px"}} className="button"  onClick={(e)=>{groupSubmit(1)}}  >
                                                    {noNumberTotal}
                                                </button>
                                            </div>
                                            
                                        <div className="crossingParent">
                                            <div className="crossing-box">
                                                    <span>First Number</span>
                                                    <input className="edittext" type="number"
                                                        style={{width:"100%", marginBottom:"10px"}}
                                                        value={noNumber}
                                                        onChange={(e)=>{changeNoNumber(1,e.target.value)}}
                                                    />
                                                </div>
                                            
                                                <div className="crossing-box">
                                                    <span>Second Number</span>
                                                    <input className="edittext" type="number"
                                                        style={{width:"100%", marginBottom:"10px"}}
                                                        value={noNumber2}
                                                        onChange={(e)=>{changeNoNumber(2,e.target.value)}}
                                                    />
                                                </div>
                                            
                                        </div>

                                
                                            
                                        <div style={{display:"flex", justifyContent:"center"}}>
                                                        
                                            <div className="crossing-box">
                                                        <span>Enter Amount</span>
                                                        <input className="edittext" type="number"
                                                            style={{width:"100%", marginBottom:"10px"}}
                                                            value={noNumberAmount}
                                                            onChange={(e)=>{changeNoNumber(3, e.target.value)}}
                                                        />
                                                    </div>
                                        </div>

                                        <div className="crossing-bets-parent" style={{paddingBottom:"75px"}}>
                                        {noNumberBets.map((number) => {
                                                return (
                                                    <div className="crossing-bets-child">
                                                        <input className="edittext" type="text"
                                                        value={number}
                                                                style={{width:"100%", marginBottom:"10px", textAlign:"center"}} readOnly
                                                            />
                                                    </div>
                                                )
                                        })}
                                            
                                        </div>

                                    </div>


                                    <div
                                        className="tab-pane fade"
                                        id="openplay"
                                        role="tabpanel"
                                        aria-labelledby="openplay-tab"
                                    >
                                    
                                        <div className="place-parent">
                                                <button style={{marginTop:"10px"}} className="button" onClick={(e)=>{groupSubmit(3)}} >
                                                    {openTotal}
                                                </button>
                                            </div>
                                            
                                        <div className="crossingParent">
                                            <div className="crossing-box">
                                                <span>Enter Numbers</span>
                                                        <input className="edittext" type="number"
                                                            style={{width:"100%", borderRadius:"10px 10px 0px 0px"}}
                                                            value={openNumber}
                                                            onChange={(e)=>{changeOpenNumber(1,e.target.value)}}
                                                        />
                                                          <div className="open-box-child" style={{display:"flex", justifyContent:"center"}}>
                                                            <input type="checkbox" id="jodi_cut" name="jodi_cut" value="jodi_cut"
                                                                onChange={e => changeOpenNumber(2, e.target.checked)}
                                                            />
                                                            <span style={{marginLeft:"10px"}}>With Palti</span>
                                                        </div>
                                                </div>
                                            
                                                               
                                            <div className="crossing-box">
                                                        <span>Enter Amount</span>
                                                        <input className="edittext" type="number"
                                                            style={{width:"100%", marginBottom:"10px"}}
                                                            value={openAmount}
                                                            onChange={(e)=>{changeOpenNumber(3, e.target.value)}}
                                                        />
                                                    </div>
                                            
                                        </div>

                                        <div className="crossingParent" style={{marginTop:"10px"}}>
                                            <div className="crossing-box">
                                                    <span>Enter Harup</span>
                                                    <input className="edittext" type="number"
                                                        style={{width:"100%", borderRadius:"10px 10px 0px 0px"}}
                                                        value={openHNumber}
                                                        onChange={(e)=>{changeOpenHNumber(1,e.target.value)}}
                                                    />
                                                    <div className="open-box-child">
                                        <div className="crossingParent" style={{width:"100%"}}>
                                            <div className="crossing-box">
                                                <div style={{display:"flex", justifyContent:"center"}}>
                                                    <input type="checkbox" id="jodi_cut" name="jodi_cut" value="jodi_cut"
                                                        onChange={e => changeOpenHNumber(2,e.target.checked)}
                                                    />
                                                    <span style={{marginLeft:"10px"}}>A</span>
                                                </div>
                                            </div>
                                            <div className="crossing-box">
                                                <div style={{display:"flex", justifyContent:"center"}}>
                                                    <input type="checkbox" id="jodi_cut" name="jodi_cut" value="jodi_cut"
                                                        onChange={e => changeOpenHNumber(3,e.target.checked)}
                                                    />
                                                    <span style={{marginLeft:"10px"}}>B</span>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <span className="error-msg">{openHErrorMsg}</span>
                                                </div>
                                            
                                                           
                                                <div className="crossing-box">
                                                        <span>Enter Amount</span>
                                                        <input className="edittext" type="number"
                                                            style={{width:"100%", marginBottom:"10px"}}
                                                            value={openHAmount}
                                                            onChange={(e)=>{changeOpenHNumber(4, e.target.value)}}
                                                        />
                                                    </div>
                                            
                                        </div>


                            
                                        <div className="crossing-bets-parent" style={{paddingBottom:"80px"}}>
                                        {openBets.map((number) => {
                                                return (
                                                    <div className="crossing-bets-child">
                                                        <input className="edittext" type="text"
                                                        value={number}
                                                                style={{width:"100%", textAlign:"center", borderRadius:"10px 10px 0px 0px"}} readOnly
                                                            />
                                                                  <p style={{marginBottom:"10px", width:"100%", borderRadius:"0px 0px 10px 10px", background:"#f38106", color:"white"}}>{openAmount}</p>
                                                 
                                                    </div>
                                                )
                                        })}

                                        {openHBets.map((number) => {
                                                return (
                                                    <div className="crossing-bets-child">
                                                        <input className="edittext" type="text"
                                                        value={number}
                                                                style={{width:"100%", textAlign:"center", borderRadius:"10px 10px 0px 0px"}} readOnly
                                                            />
                                                            <p style={{marginBottom:"10px", width:"100%", borderRadius:"0px 0px 10px 10px", background:"#f38106", color:"white"}}>{openHAmount}</p>
                                                    </div>
                                                )
                                        })}
                                            
                                        </div>

                                    </div>
                                </div>
                                </>

                            </div>


                    </div>

                </div>
            </div>

        </>
    );
};

export default Home;
