import React from "react";
import {Link} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import axios from "axios";
import Swal from "sweetalert2";


class Gali extends React.Component {

    constructor() {


        super();
        this.state = {
            single: "-",
            double: "-",
            wallet: "0",
            results:[],
            width_b:"0%",
            width:"0%",
            nav_display:"none",
            transfer_point:"none",
        }


    }

    componentDidMount() {


        var form = new FormData();
        form.append("session", localStorage.getItem("token"));
        form.append("mobile", localStorage.getItem("mobile"));

        axios
            .post("https://sattads.com/manage/"+"/api2/delhi_markets.php", form,
            )
            .then((response) => {
                if(response.status === 200){
                    if (response.data.nosession){
                        Swal.fire({
                            toast: true,
                            title: 'Oops...',
                            text: "Session Expired"
                        }).then((e)=>{
                            localStorage.clear()
                            window.location.href = "/login";
                        })
                    }
                    let transfer;
                    if (response.data.transfer_points_status === "1"){
                        transfer = "flex";
                    } else {
                        transfer = "none";
                    }
                    this.setState({
                        transfer_point: transfer,
                        results:response.data.data,
                        single:response.data.single,
                        double:response.data.jodi
                    })
                } else if(response.status === 401){
                    //  navigate("/login")
                }
            })
    }


    render() {

        let marketCloseError = ()=>{
            Swal.fire({
                toast: true,
                title: 'Oops...',
                text: "Market Already Closed, Please come again tomorrow"
            })
        }

        return (<>
                <div className="RootView">
                    <div className="RootWidth">

                        <div className="loginDiv">


                            <div className="toolbar">
                                <div>
                                    <img onClick={()=>{window.history.go(-1)}}  src="../images/back.png" style={{padding: "5px"}}/>
                                    <span>Satta DS</span>
                                </div>


                            </div>



                            <div style={{padding:"5px"}}>

                              <div className="border_boxer">
                                    <span>Single {this.state.single}</span>
                                    <span>Jodi {this.state.double}</span>
                                    <div style={{display:"flex"}}>
                                        <Link className="block_button" to={"/gameHistory?type=delhi"}>
                                            <span className="button_title">Bid<br></br>History</span>
                                        </Link>

                                        <Link className="block_button" to={"/winnings?type=delhi"}>
                                            <span className="button_title">Winning<br></br>History</span>
                                        </Link>

                                    </div>
                                </div>

                                {this.state.results &&

                                    this.state.results.map((result) => {
                                        let marketStatus = ""
                                        if(result.is_close === "0") { marketStatus = "Market Closed" } else { marketStatus = "Market Open" }
                                        return (

                                <div className="market_box" key={result.market}>
                                    <div className="times">
                                        <span className="market_time" style={{width:"100%", textAlign:"center"}}>Open : {result.time}</span>
                                    </div>
                                    <div className="main">
                                        <div className="inner_market_box" style={{textAlign:"left"}}>
                                     
                                        </div>
                                        <div className="inner_market_box" style={{textAlign:"center"}}>
                                            <span className="market_name">{result.market}</span>
                                            {result.is_open === "1" ?
                                                <span className="market_result" style={{fontSize:13, color:"green"}}>Market Running</span>
                                                :
                                                <span className="market_result" style={{fontSize:13, color:"red"}}>Market Closed</span>
                                            }
                                            <span className="market_result">{result.result}</span>
                                        </div>
                                        <div className="inner_market_box" style={{alignItems:"flex-end"}}>
                                            { result.is_open === "1" ?
                                                <Link to={'/galiGames'} state={[{market: result.market, is_open: "1"}]}>
                                              <div className="play_result">
                                              <img src="../images/play_white.png" />
                                                  <span>Play</span>
                                              </div>
                                          </Link>
                                              :  <Link style={{opacity:"0"}}>
                                              <div className="play_result">
                                              <img src="../images/play_white.png" />
                                                  <span>Play</span>
                                              </div>
                                          </Link> }

                                        </div>
                                    </div>
                                </div>

                                        )})}

                            </div>

                        </div>


                    </div>
                </div>


            </>
        );
    }

}

export default Gali;
