import { useCallback , useState } from "react";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import styles from "../style/Login.module.css"
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";
import {bindActionCreators} from "redux";
import {actions} from "../state/index"

const Login = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const state = useSelector(state => state.session)

    const mobile = location.state.mobile

    const dispatch = useDispatch()
    const {setSession, getSession} = bindActionCreators(actions, dispatch)

    const [pass, setPassword] = useState("");
    const [otp, setotp] = useState("");

    const baseurl = "https://sattads.com/manage/";

    let loginSubmit = (event) =>{
        event.preventDefault();

        var form = new FormData();
        form.append("mobile", mobile);
        form.append("otp", otp);
        form.append("pass", pass);

        axios
            .post(`${baseurl}/api2/reset_password.php`, form)
            .then((response) => {
                if(response.data.success === "0"){
                    Swal.fire({
                        toast: true,
                        title: 'Oops...',
                        text: response.data.msg,
                    })

                } else {
                    Swal.fire({
                        toast: true,
                        title: 'Password Updated',
                        text: response.data.msg,
                    }).then(()=>{
                        navigate({
                            pathname: "/login",
                        })
                    })

                }
            })

        if (state){
            return <Navigate to={"/home"} />
        }
    }

    const onForgotClick = useCallback(() => {
        navigate("/forgot");
    }, [navigate]);

    return (
        <div className="RootView">
            <div className="RootWidth authBack">
                <div className={styles.loginDiv}>
                    <img className="logoIcon" alt="" src="../images/logo.png" />

                    <form
                        className={styles.frameForm}
                        method="post"
                        onSubmit={loginSubmit}
                    >
                        <div className="pageHeadings">Reset Password</div>
                        <span>We have sent a 4 digit otp on your mobile number</span>
                        <input
                            className="edittext"
                            name="otp"
                            type="number"
                            value={otp}
                            onChange={(e)=>{setotp(e.target.value)}}
                            placeholder="Enter OTP"
                        />
                        <input
                            className="edittext"
                            name="password"
                            type="password"
                            value={pass}
                            onChange={(e)=>{setPassword(e.target.value)}}
                            placeholder="Enter New Password"
                        />

                        <button className="button">
                            <b>Submit</b>
                        </button>

                    </form>

                </div>
            </div>
        </div>
    );
};

export default Login;
